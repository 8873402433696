import { getNetworksAndCurrenciesFromConfig } from '@noah-labs/fe-shared-data-access-wallet';
import { TpCheckoutPaymentStatus, TpCheckoutPayoutStatus } from '@noah-labs/fe-shared-util-shared';
import { AccountType } from '@noah-labs/shared-schema-gql';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function createWalletRoutes() {
  // URL FORMAT
  // /wallet/<Action>/<CurrencyCode>/<AccountType>/<Network>/<Step>
  // Action = Buy, Sell, Receive, Send

  /**
   * Ensure that only the routes we specify in our configs are available,
   * so that we know that if the route renders, it must be one of enums
   */
  const [networksFromConfig, currenciesFromConfig] = getNetworksAndCurrenciesFromConfig();
  const currencyCodes = currenciesFromConfig.join('|').toLowerCase();
  const networks = networksFromConfig.join('|').toLowerCase();
  const accountTypes = Object.keys(AccountType).join('|').toLowerCase();
  const paymentStatuses = Object.keys(TpCheckoutPaymentStatus).join('|').toLowerCase();
  const payoutStatuses = Object.keys(TpCheckoutPayoutStatus).join('|').toLowerCase();

  const currencyCode = `:currencyCode(${currencyCodes})`;
  const network = `:network(${networks})`;
  const accountType = `:accountType(${accountTypes})`;

  return {
    /**
     * If walletRoutes change, be sure to update any links in the website
     */
    account: {
      base: `/wallet/${currencyCode}/${accountType}`,
      transactions: `/wallet/${currencyCode}/${accountType}/transactions/:id`,
    },
    base: '/wallet',
    buy: {
      /**
       * If buy routes change, be sure to update `libs/cloud/shared/constants/src/getDomains.ts` to have the correct url for 3DS redirects
       */
      base: `/wallet/buy/${currencyCode}/${accountType}`,
      complete: `/wallet/buy/${currencyCode}/${accountType}/complete/:paymentStatus(${paymentStatuses})`,
      confirm: `/wallet/buy/${currencyCode}/${accountType}/confirm`,
      enterAmount: `/wallet/buy/${currencyCode}/${accountType}`,
      payment: `/wallet/buy/${currencyCode}/${accountType}/payment`,
    },
    receive: {
      base: '/wallet/receive', // select account
      lightning: {
        enterAmount: `/wallet/receive/${currencyCode}/${accountType}/lightning`,
        viewInvoice: `/wallet/receive/${currencyCode}/${accountType}/lightning/view-invoice`,
      },
      network: `/wallet/receive/${currencyCode}/${accountType}/network`,
      viewAddress: `/wallet/receive/${currencyCode}/${accountType}/${network}/address`,
    },
    sell: {
      base: `/wallet/sell/${currencyCode}/${accountType}`, // payoutMethod
      complete: `/wallet/sell/${currencyCode}/${accountType}/complete/:payoutStatus(${payoutStatuses})`,
      confirm: `/wallet/sell/${currencyCode}/${accountType}/confirm`,
      enterAccountDetails: `/wallet/sell/${currencyCode}/${accountType}/payment/bank`,
      enterAmount: `/wallet/sell/${currencyCode}/${accountType}/enter-amount`,
      enterPaymentInfo: `/wallet/sell/${currencyCode}/${accountType}/payment/card`,
      payoutMethod: `/wallet/sell/${currencyCode}/${accountType}`,
    },
    send: {
      address: {
        base: `/wallet/send/${currencyCode}/${accountType}/address`,
        scan: `/wallet/send/${currencyCode}/${accountType}/address/scan`,
      },
      base: '/wallet/send', // select account
      complete: `/wallet/send/${currencyCode}/${accountType}/${network}/complete`,
      confirm: `/wallet/send/${currencyCode}/${accountType}/${network}/confirm`,
      enterAmount: `/wallet/send/${currencyCode}/${accountType}/${network}/enter-amount`,
      lightning: {
        base: `/wallet/send/${currencyCode}/${accountType}/lightning/`,
        complete: `/wallet/send/${currencyCode}/${accountType}/lightning/complete`,
        confirm: `/wallet/send/${currencyCode}/${accountType}/lightning/confirm`,
        enterAmount: `/wallet/send/${currencyCode}/${accountType}/lightning/enter-amount`,
        invoiceExpired: `/wallet/send/${currencyCode}/${accountType}/lightning/invoice-expired`,
        request: `/wallet/send/${currencyCode}/${accountType}/lightning/request/:source/:encodedSendParams`,
      },
      network: `/wallet/send/${currencyCode}/${accountType}/network`,
      withParams: `/wallet/send/${currencyCode}/${accountType}/${network}`,
    },
  };
}

let walletRoutesCached: ReturnType<typeof createWalletRoutes> | undefined;

export function walletRoutes(): ReturnType<typeof createWalletRoutes> {
  if (walletRoutesCached) {
    return walletRoutesCached;
  }
  return createWalletRoutes();
}
